import "./page.scss";

const Welcome = ({
  nextPage,
  duration,
}: {
  nextPage: any;
  duration: number;
}) => {
  if (duration > 0) {
    setTimeout(nextPage, duration);
  }

  return (
    <div className="page-wrapper">
      <h1>Welcome!</h1>
      <div onClick={() => nextPage()}>HERE</div>
    </div>
  );
};

export default Welcome;

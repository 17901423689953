import "./page.scss";
import SquatContainer from "../Squat/";
import { useCount } from "../Counter/count-context";

const CountDisplay = () => {
  const {
    state: { count },
  } = useCount();
  return (
    <div className="the-counter">
      <h2>{count}</h2>
    </div>
  );
};

const Game = ({ nextPage, duration }: { nextPage: any; duration: number }) => {
  if (duration > 0) {
    setTimeout(nextPage, duration);
  }
  return (
    <div className="page-wrapper">
      <h1>Game!</h1>
      <SquatContainer />
      <CountDisplay />
    </div>
  );
};

export default Game;

import SquatPose from "./SquatPose";
import Counter from "./Counter";

import { useState } from "react";
import "./wrapper.scss";

const SquatContainer = () => {
  return (
    <div className="squat-wrapper">
      <SquatPose />
    </div>
  );
};

export default SquatContainer;

import { useEffect, useRef, useState } from "react";
import {
  Results,
  Pose,
  POSE_CONNECTIONS,
  VERSION,
  NormalizedLandmark,
} from "@mediapipe/pose";
import {
  drawConnectors,
  drawLandmarks,
  Data,
  lerp,
} from "@mediapipe/drawing_utils";
import "./index.scss";

import { useCount } from "../Counter/count-context";

const SquatPose = () => {
  const { dispatch } = useCount();

  const inputVideoReady = useRef(false);
  const loaded = useRef(false);
  let stage: String;
  let prev_ankle_l: NormalizedLandmark;
  const inputVideoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);

  const [hipKneeAngleL, setHipKneeAngleL] = useState(0);
  const [hipKneeAngleR, setHipKneeAngleR] = useState(0);

  const calculateAngle = (a: NormalizedLandmark, b: NormalizedLandmark) => {
    var radians = Math.atan2(a.y - b.y, a.x - b.x);
    var angle = Math.abs((radians * 180.0) / Math.PI);
    if (angle > 180.0) {
      angle = 360 - angle;
    }
    return angle;
  };
  const calculateDistanceSquared = (
    a: NormalizedLandmark,
    b: NormalizedLandmark
  ) => {
    return (a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y) * 10000;
  };

  const onResults = (results: Results) => {
    if (canvasRef.current && contextRef.current) {
      loaded.current = true;

      contextRef.current.save();
      contextRef.current.clearRect(0, 0, window.innerWidth, window.innerHeight);
      contextRef.current.drawImage(
        results.image,
        0,
        0,
        window.innerWidth,
        window.innerHeight
      );

      if (results.poseLandmarks) {
        let hip_r = results.poseLandmarks[23];
        let hip_l = results.poseLandmarks[24];
        let knee_r = results.poseLandmarks[25];
        let knee_l = results.poseLandmarks[26];
        let ankle_r = results.poseLandmarks[27];
        let ankle_l = results.poseLandmarks[28];

        let hip_knee_angle_r = Math.round(calculateAngle(hip_r, knee_r));
        let hip_knee_angle_l = Math.round(calculateAngle(hip_l, knee_l));

        // for debug purposes
        // hipKneeAngleR.current = hip_knee_angle_r;
        // hipKneeAngleL.current = hip_knee_angle_l;

        if (hip_knee_angle_r > 30 && hip_knee_angle_l > 30) {
          stage = "down";
          prev_ankle_l = ankle_l;
        }

        if (hip_knee_angle_r < 20 && hip_knee_angle_l < 20 && stage === "down" && calculateDistanceSquared(ankle_l, prev_ankle_l) < 1) {
          stage = "up";
          dispatch({ type: "increment" });
          console.log(
            "up: " + calculateDistanceSquared(ankle_l, prev_ankle_l).toString()
          );
          prev_ankle_l = ankle_l;
        }
        drawConnectors(
          contextRef.current,
          results.poseLandmarks,
          POSE_CONNECTIONS,
          {
            color: "#00FF00",
          }
        );
        drawLandmarks(contextRef.current, results.poseLandmarks, {
          visibilityMin: 0.75,
          color: "white",
          fillColor: "rgb(0,0,0)",
        });
      }
      contextRef.current.restore();
    }
  };

  useEffect(() => {
    console.log("effect!");
    if (!inputVideoReady) {
      return;
    }
    if (inputVideoRef.current && canvasRef.current) {
      contextRef.current = canvasRef.current.getContext("2d");
      const constraints = {
        video: { width: window.innerWidth, height: window.innerHeight },
      };
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        if (inputVideoRef.current) {
          inputVideoRef.current.srcObject = stream;
        }
        sendToMediaPipe();
      });

      const pose = new Pose({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${VERSION}/${file}`,
      });

      pose.setOptions({
        selfieMode: true,
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });
      inputVideoRef.current.classList.toggle("selfie", true);
      pose.onResults(onResults);

      const sendToMediaPipe = async () => {
        if (inputVideoRef.current) {
          if (!inputVideoRef.current.videoWidth) {
            console.log(inputVideoRef.current.videoWidth);
            requestAnimationFrame(sendToMediaPipe);
          } else {
            await pose.send({ image: inputVideoRef.current });
            requestAnimationFrame(sendToMediaPipe);
          }
        }
      };
    }
  }, [inputVideoReady]);

  return (
    <div className="pose-container">
      <div className="debug">
        <div>hipKneeAngleR: {hipKneeAngleR}</div>
        <div>hipKneeAngleL: {hipKneeAngleL}</div>
      </div>
      <video
        autoPlay
        ref={(el) => {
          inputVideoRef.current = el;
          console.log("setting inputVideoReady to", !!el);
          inputVideoReady.current = true;
        }}
      />
      <canvas
        ref={canvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      {!loaded && (
        <div className="loading">
          <div className="spinner"></div>
          <div className="message">Loading</div>
        </div>
      )}
    </div>
  );
};

export default SquatPose;

import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import GameContainer from "./components/GameContainer";
import { CountProvider } from "./components/Counter/count-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <CountProvider>
      <GameContainer />
    </CountProvider>
  </>
);

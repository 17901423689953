import { useState } from "react";

import Welcome from "./pages/Welcome";
import Intro from "./pages/Intro";
import Game from "./pages/Game";
import Done from "./pages/Done";

const pageComponents = [
  { component: Welcome, duration: 0 },
  { component: Intro, duration: 2000 },
  { component: Game, duration: 0 },
  { component: Done, duration: 0 },
];

const GenericPage = (props: any) => {
  const { component, duration } = pageComponents[props.idx];
  const ThePage = component;
  return <ThePage nextPage={props.nextPage} duration={duration} />;
};

const GameContainer = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const nextPage = () => {
    if (pageIndex + 1 < pageComponents.length) {
      setPageIndex(pageIndex + 1);
    }
  };

  return (
    <div className="page-wrapper">
      <GenericPage idx={pageIndex} nextPage={nextPage} />
    </div>
  );
};

export default GameContainer;

import "./page.scss";

const Done = ({ nextPage, duration }: { nextPage: any; duration: number }) => {
  if (duration > 0) {
    setTimeout(nextPage, duration);
  }
  return (
    <div className="page-wrapper">
      <h1>Done!</h1>
    </div>
  );
};

export default Done;
